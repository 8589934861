<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="6">
          <div class="font-weight-light text-h3 font-weight-medium">
            {{ transcript_title }}
          </div>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="selectedRegistration"
            :items="transcripts"
            item-text="lbkey"
            item-value="id"
            :label="$t('plan.select_key')"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text v-if="currentRegistration">
      <h3>{{ $t("transcript.markData") }}</h3>
      <v-row v-for="(row, rowIndex) in markRows" :key="rowIndex">
        <v-col v-for="(item, colIndex) in row" :key="colIndex" cols="2">
          <v-textarea
            v-if="isTextArea(item.key)"
            :value="displayTextArea(item)"
            :label="item.key"
            :rows="3"
            readonly
          ></v-textarea>
          <v-text-field
            v-else
            v-model="item.value"
            :label="item.key"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <h3 class="mt-4">{{ $t("transcript.ownerData") }}</h3>
      <v-data-table
        :headers="oHeaders"
        :items="currentRegistration.o_data"
        :items-per-page="5"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn small color="primary" @click="editItem(item)">{{
            $t("plan.view")
          }}</v-btn>
        </template>
      </v-data-table>

      <h3 class="mt-4">{{ $t("transcript.creditorData") }}</h3>
      <v-data-table
        :headers="cHeaders"
        :items="currentRegistration.c_data"
        :items-per-page="5"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn small color="primary" @click="editItem(item)">{{
            $t("plan.view")
          }}</v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <reg-no-detail
      v-model="dialog"
      :item="editedItem"
      @close="dialog = false"
    ></reg-no-detail>
  </v-card>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import RegNoDetail from "@/views/Pages/CRM/Widgets/RegNoDetail";
import { TranscriptType } from "@/definitions";
export default {
  props: {
    propTranscripts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      TranscriptType: TranscriptType,
      transcripts: this.propTranscripts,
      selectedRegistration: 1,
      oHeaders: [
        { text: "登記次序", value: "登記次序" },
        { text: "所有權人姓名", value: "所有權人姓名" },
        { text: "住址", value: "住址" },
        { text: "權利範圍", value: "權利範圍" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      cHeaders: [
        { text: "登記次序", value: "登記次序" },
        { text: "權利人姓名", value: "權利人姓名" },
        { text: "住址", value: "住址" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      dialog: false,
      editedItem: {},
    };
  },
  components: {
    RegNoDetail,
  },
  watch: {
    propTranscripts: {
      deep: true,
      handler: function (newVal, oldVal) {
        this.transcripts = newVal;
      },
    },
  },
  computed: {
    ...mapState(["transcriptData"]),
    currentRegistration() {
      //find by id
      return (
        this.transcripts.find((r) => r.id === this.selectedRegistration) || null
      );
      // return (
      //   this.transcripts.find((r) => r.lbkey === this.selectedRegistration) ||
      //   null
      // );
    },
    markRows() {
      if (!this.currentRegistration) return [];
      const mark = this.currentRegistration.mark;
      const entries = Object.entries(mark);
      const rows = [];
      for (let i = 0; i < entries.length; i += 6) {
        rows.push(
          entries.slice(i, i + 6).map(([key, value]) => ({ key, value }))
        );
      }
      return rows;
    },
    transcript_title() {
      if (this.currentRegistration != null) {
        let transcript_type = this.currentRegistration.transcript_type;
        switch (transcript_type) {
          case TranscriptType.Telex:
            return this.$t("transcript.telex");
          case TranscriptType.Class_1:
            return this.$t("transcript.class_1");
          case TranscriptType.Class_2:
            return this.$t("transcript.class_2");
          case TranscriptType.Class_3:
            return this.$t("transcript.class_3");
        }
      }
      return this.$t("product.Transcript");
    },
  },
  methods: {
    ...mapMutations(["setTranscriptData"]), // Add this line
    reset() {
      this.selectedRegistration = 1;
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    submitRegistration() {
      // Implement submit functionality
      console.log("Submit registration:", this.currentRegistration);
    },
    setSelectedRegistration(lbkey) {
      this.selectedRegistration = lbkey;
    },
    updateOwnerData(data) {
      let { lbkey, regno, label, data: str } = data;
      // console.log(
      //   `lbkey: ${lbkey}, regno: ${regno}, label: ${label}, str: ${str}`
      // );

      // Find all transcript indices that match the lbkey
      this.transcripts.forEach((transcript, transcriptIndex) => {
        if (transcript.lbkey === lbkey) {
          // Check o_data first
          let ownerDataIndex = transcript.o_data.findIndex(
            (r) => r["登記次序"] === regno
          );

          if (ownerDataIndex !== -1 ) {
            if(this.transcripts[transcriptIndex].o_data[ownerDataIndex][label] != null) {
              console.log("Already set",regno, label, str);
              return;
            }
            Vue.set(
              this.transcripts[transcriptIndex].o_data[ownerDataIndex],
              label,
              str
            );
          } else {
            // If not found in o_data, check c_data
            ownerDataIndex = transcript.c_data.findIndex(
              (r) => r["登記次序"] === regno
            );
            if (ownerDataIndex !== -1) {
              Vue.set(
                this.transcripts[transcriptIndex].c_data[ownerDataIndex],
                label,
                str
              );
            }
          }
        }
      });
    },
    isTextArea(key) {
      // 層次 其他登記事項
      let keys = ["層次", "其他登記事項", "共有部份", "附屬建物"];

      if (keys.includes(key)) {
        return true;
      }

      return false;
    },
    displayTextArea(item) {
      let key = item.key;
      if (key == "層次") {
        let text = "";
        for (let i = 0; i < item.value.length; i++) {
          console.log(item.value[i]);
          let level = item.value[i]["層次"];
          let area = item.value[i]["層次面積"];
          text += `${level} ${area}\n`;
        }
        return text;
      } else if (key == "共有部份") {
        let text = "";
        try {
          for (let i = 0; i < item.value.length; i++) {
            let info = item.value[i];
            // get the info key
            let infoKey = Object.keys(info)[0];
            let value = info[infoKey];
            let scope = value["權利範圍"];
            let area = value["面積"];
            let msg = `權利範圍:${scope} 面積:${area}`;
            text += `${infoKey} ${msg}\n`;
          }
        } catch (error) {
          console.error("Error:", error);
          // Handle the error here
          // You can log the error, show an error message, or perform any other necessary action
          // For example, you can set a default value for text or display a placeholder message
          text = "Error occurred while processing the data\n";
        }
        return text;
      } else if (key == "附屬建物") {
        let text = "";
        try {
          for (let i = 0; i < item.value.length; i++) {
            let info = item.value[i];
            // get the info key
            let purpose = info["附屬建物用途"];
            let area = info["面積"];
            text += `${purpose}: ${area}\n`;
          }
        } catch (error) {
          console.error("Error:", error);
          // Handle the error here
          // You can log the error, show an error message, or perform any other necessary action
          // For example, you can set a default value for text or display a placeholder message
          text = "Error occurred while processing the data\n";
        }
        return text;
      }
      return item.value;
    },
  },

  mounted() {
    // if (this.transcripts.length > 0) {
    //   this.selectedRegistration = this.transcripts[0].lbkey;
    // }
  },
};
</script>
